import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import createHelpers from './createHelpers';

export default function configureStore (initialState, helpersConfig) {
    const helpers = createHelpers(helpersConfig);
    const middleware = [thunk.withExtraArgument(helpers)];

    const enhancer = applyMiddleware(...middleware);
    const store = createStore(rootReducer, initialState, enhancer);
    if (__DEV__ && module.hot) {
        module.hot.accept('../reducers', () =>
            // eslint-disable-next-line global-require
            store.replaceReducer(require('../reducers').default),
        );
    }

    return store;
}