// The top-level (parent) route
const routes = {
    path: '/',
    load: () => import(/* webpackChunkName: 'layout' */ './layout'),
    // Keep in mind, routes are evaluated in order
    children: [
        {
            path: '/',
            load: () => import(/* webpackChunkName: 'home' */ '../pages/home'),
        },
        {
            path: '/ershoufang/list',
            load: () => import(/* webpackChunkName: 'tradelist' */ '../pages/list/trade'),
        },
        {
            path: '/zufang/list',
            load: () => import(/* webpackChunkName: 'rentlist' */ '../pages/list/rent'),
        },
        {
            path: '/xinfang/list',
            load: () => import(/* webpackChunkName: 'newhouselist' */ '../pages/list/newhouse'),
        },
        {
            path: '/baozf/list',
            load: () => import(/* webpackChunkName: 'baozufanglist' */ '../pages/list/baozufang'),
        },
        {
            path: '/ershoufang/detail',
            load: () => import(/* webpackChunkName: 'trade' */ '../pages/detail/trade'),
        },
        {
            path: '/zufang/detail',
            load: () => import(/* webpackChunkName: 'rent' */ '../pages/detail/rent'),
        },
        {
            path: '/xinfang/detail',
            load: () => import(/* webpackChunkName: 'newhouse' */ '../pages/detail/newhouse'),
        },
        {
            path: '/xinfang/xiangce',
            load: () => import(/* webpackChunkName: 'albums' */ '../pages/albums'),
        },
        {
            path: '/xinfang/huxing',
            load: () => import(/* webpackChunkName: 'frame' */ '../pages/frame'),
        },
        {
            path: '/xinfang/dongtai',
            load: () => import(/* webpackChunkName: 'news' */ '../pages/news'),
        },
        {
            path: '/baozf/detail',
            load: () => import(/* webpackChunkName: 'baozufang' */ '../pages/detail/baozufang'),
        },
        {
            path: '/policy',
            load: () => import(/* webpackChunkName: 'policy' */ '../pages/policy'),
        },
        {
            path: '/protocol',
            load: () => import(/* webpackChunkName: 'protocol' */ '../pages/protocol'),
        },
        {
            path: '/focushouse',
            load: () => import(/* webpackChunkName: 'chinahouse' */ '../pages/chinahouse'),
        },
        {
            path: '/mapfind',
            load: () => import(/* webpackChunkName: 'mapfind' */ '../pages/mapfind'),
        },
        //新增 金融服务
        {
            path: '/financialService',
            load: () => import(/* webpackChunkName: 'financialService' */ '../pages/financialService'),
        },
        // 新增 适老改造
        {
            path: '/elderlyRenovation',
            load: () => import(/* webpackChunkName: 'elderlyRenovation' */ '../pages/elderlyRenovation'),
        },
        // 新增 适老改造 / 优秀案例
        {
            path: '/elderlyRenovation/list',
            load: () => import(/* webpackChunkName: 'elderlyRenovationList' */ '../pages/elderlyRenovation/list'),
        },
        // 新增 适老改造 / 套餐详情
        {
            path: '/elderlyRenovation/detail',
            load: () => import(/* webpackChunkName: 'elderlyRenovationDetail' */ '../pages/elderlyRenovation/detail'),
        },
        // 新增 适老改造 / 政策解答
        {
            path: '/elderlyRenovation/answer',
            load: () => import(/* webpackChunkName: 'elderlyRenovationAnswer' */ '../pages/elderlyRenovation/answer'),
        },
        
        // 新增 精商优选
        {
            path: '/decorationProcurement',
            load: () => import(/* webpackChunkName: 'decorationProcurement' */ '../pages/decorationProcurement'),
        },
        // 新增 关于我们
        {
            path: '/aboutUs',
            load: () => import(/* webpackChunkName: 'aboutUs' */ '../pages/aboutUs'),
        },
        // 新增  政策展示
        {
            path: '/policyDisplay',
            load: () => import(/* webpackChunkName: 'policyDisplay' */ '../pages/policyDisplay'),
        },
        // 新增  以旧换新
        {
            path: '/tradeIn',
            load: () => import(/* webpackChunkName: 'tradeIn' */ '../pages/tradeIn'),
        },
        // 新增  通用页面
        {
            path: '/generalPage',
            load: () => import(/* webpackChunkName: 'generalPage' */ '../pages/generalPage'),
        },
        // 新增  在线商城
        {
            path: '/onlineShop',
            load: () => import(/* webpackChunkName: 'onlineShop' */ '../pages/onlineShop'),
        },
        // 新增  在线商城 /筛选页面
        {
            path: '/onlineShop/seachShop',
            load: () => import(/* webpackChunkName: 'seachShop' */ '../pages/onlineShop/seachShop'),
        },
        // 新增  在线商城 / 商品详情页面
        {
            path: '/onlineShop/goodsDetail',
            load: () => import(/* webpackChunkName: 'goodsDetail' */ '../pages/onlineShop/goodsDetail'),
        },
        // 新增 商家展示列表-更多页面
        {
            path: '/onlineShop/shopShow',
            load: () => import(/* webpackChunkName: 'shopShow' */ '../pages/onlineShop/shopShow'),
        },
        // 新增 商家展示详情
        {
            path: '/onlineShop/shopShowDetail',
            load: () => import(/* webpackChunkName: 'shopShowDetail' */ '../pages/onlineShop/shopShowDetail'),
        },
        // 新增 我要入驻
        {
            path: '/toJoin',
            load: () => import(/* webpackChunkName: 'toJoin' */ '../pages/toJoin'),
        },
        
        // 新增 购物车结算页面
        {
            path: '/onlineShop/settlement',
            load: () => import(/* webpackChunkName: 'settlement' */ '../pages/onlineShop/settlement'),
        },
        // 新增 入驻商家
        {
            path: '/storeList',
            load: () => import(/* webpackChunkName: 'storeList' */ '../pages/storeList'),
        },
        // 新增 入驻说明
        {
            path: '/entryInstructions',
            load: () => import(/* webpackChunkName: 'entryInstructions' */ '../pages/entryInstructions'),
        },
        {
            path: '/subsidy/list',
            load: () => import(/* webpackChunkName: 'subsidyList' */ '../pages/list/subsidy'),
        },
        {
            path: '/cityPlan/list',
            load: () => import(/* webpackChunkName: 'cityPlanList' */ '../pages/list/cityPlan'),
        },
        {
            path: '/404',
            load: () => import(/* webpackChunkName: '404' */ '../pages/404'),
        },
        {
            path: '(.*)',
            load: () => import(/* webpackChunkName: 'notfound' */ './notfound'),
        }
    ],
    async action ({ next }) {
        // Execute each child route until one of them return the result
        const route = await next();
        // Provide default values for title, description etc.
        route.title = `${route.title || '东楚优房'}`;
        route.description = route.description || '东楚优房';

        return route;
    },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
    routes.children.unshift({
        path: '/error',
        action: require('./error').default,
    });
}

export default routes;
